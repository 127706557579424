<template>
   <div>
      <router-link :to="{ name: 'EditReportType', params: { code:templateArgs.code, model: templateArgs }}">{{templateArgs.name}}</router-link>
  </div>
</template>

<script>

import Router from 'vue-router'
import Vue from 'vue'
Vue.use(Router)

export default {
  name: 'linkTemplate',
  methods: {
  },
  data () {
    return {
      templateArgs: {}
    }
  }
}
</script>
