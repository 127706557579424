<template>
    <span>
        <input class="k-checkbox" data-role="isPersistent" aria-label="Is Persistent" type="checkbox" :disabled="templateArgs.disabled" :checked="templateArgs.isPersistent" v-on:click="savePersistence($event, templateArgs.code)" />
    </span>
</template>

<script>

import Router from 'vue-router'
import Vue from 'vue'
Vue.use(Router)

export default {
  name: 'cbTemplate',
  methods: {
    async savePersistence (event, code) {
      try {
        let isPersistent = event && event.target && event.target.checked ? event.target.checked : false
        let route = `/report-types/persistence/${code}/${isPersistent}`
        await this.axios.put(route)
      } catch (error) {
        this.handleApiErr(error)
      }
    }
  },
  data () {
    return {
      templateArgs: {}
    }
  }
}
</script>
